.card-list-items {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 12px 15px;
}

.card-list-items .card-list-item:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);

}

.card-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

}

.card-list-item .s_no {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #A9A9A9;
    margin-right: 15px;
}

.card-list-item .bankname {}

.card-list-item .card_image {
    width: 108px;
    height: 60px;
    border-radius: 10px;
    margin-right: 14px;
}

.card-list-item .card_type {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #A9A9A9;
    margin-top: 4px;
}

.btn-header {
    background: #6391FF;
    border-radius: 49px;
    color: white;
    border: none;
    outline: none;
    margin-bottom: 13px;
    padding: 14px 18px;
    margin-right: 22px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
}

.btn-header svg {
    margin-left: 5px;
}

.flex-1 {
    flex: 1;
}