*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  font-family: 'std-book', sans-serif !important;
}

:root {
  --color-primary: #2565FF;
}

.bg_primary {
  background-color: var(--color-primary)
}

.icon_cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-between {
  align-items: space-between;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.p-20 {
  padding: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.m-20 {
  margin: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.overflow-x {
  overflow-x: scroll;
}

.overflow-x::-webkit-scrollbar {
  display: none;
}

/* BACKGROUND LIGHT */
.bg_sky_blue_light {
  background: rgba(1, 179, 255, 0.16);
}

.bg_blue_light {
  background: rgba(37, 101, 255, 0.16);
}

.bg_yellow_light {
  background: rgba(252, 132, 21, 0.16);
}

.bg_purple_light {
  background: rgba(153, 93, 255, 0.16);
}

.bg_green_light {
  background: rgba(2, 175, 86, 0.16);
}

.bg_red_light {
  background: rgba(235, 53, 63, 0.16);
}

/* COLOR LIGHT */
.color_red_light {
  color: rgba(235, 53, 63, 0.16);
}

.color_sky_blue_light {
  color: rgba(1, 179, 255, 0.16);
}

.colorblue_light {
  color: rgba(37, 101, 255, 0.16);
}

.color_yellow_light {
  color: rgba(252, 132, 21, 0.16);
}

.color_purple_light {
  color: rgba(153, 93, 255, 0.16);
}

.color_green_light {
  color: rgba(2, 175, 86, 0.16);
}

/* BACKGROUND FULL */
.bg_red {
  background: rgba(235, 53, 63);
}

.bg_sky_blue {
  background: rgba(1, 179, 255);
}

.bg_blue {
  background: rgba(37, 101, 255);
}

.bg_yellow {
  background: rgba(252, 132, 21);
}

.bg_purple {
  background: rgba(153, 93, 255);
}

.bg_green {
  background: rgba(2, 175, 86);
}

/* COLOR FULL */
.color_red {
  color: rgba(235, 53, 63);
}

.color_sky_blue {
  color: rgba(1, 179, 255);
}

.color_blue {
  color: rgba(37, 101, 255);
}

.color_yellow {
  color: rgba(252, 132, 21);
}

.color_purple {
  color: rgba(153, 93, 255);
}

.color_green,
.color_green path {
  color: rgba(2, 175, 86) !important;
  fill: rgba(2, 175, 86) !important;
}

body {
  background-color: rgb(248, 248, 248)
}

.page {
  min-height: 100vh;
}