.new_letter_card {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    /* width: calc(100vw - 2.5rem); */
    max-width: 500px;
    overflow: hidden;
}

.card_img_cont {
    width: 100%;
    min-height: min(14.902rem, 58vw);
    display: flex;
    align-items: center;
}

.card_img_cont.bg_orange {
    background: #E1630F;
}

.card_img_cont.bg_blue {
    background: #166EB6;
}

.card_img_cont img {
    width: 100%;
    margin-top: 1.4rem;
    max-height: 14.875rem;
    object-fit: cover;
}

.card_text_cont {
    padding: 0.855rem;
}

.news_letter_title {
    font-weight: 500;
    font-size: 15.8954px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 0.771rem;
}

.news_posted_by,
.news_posted_at {
    font-weight: 400;
    font-size: 0.71rem;
    line-height: 0.813rem;
    color: #8A8A8A;
}

.news_posted_by img {
    width: 20px;
    margin-right: 5px;
}