.shop_logos {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.611rem;
    grid-gap: 0.611rem;

}

.shop_logos .shop_logo {
    /* width: 4.625rem;
    height: 4.625rem; */
    padding: 0.15rem;
    min-width: 64px;
    min-height: 64px;
    max-height: 70px;
    width: 100%;
    height: 100%;
    border-radius: 406px;

}

.shops .drop_menu_label {
    background-color: transparent;
    display: flex;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #A9A9A9;
    width: 100px;
}

.shops .drop_menu_label .color_blue {
    color: #A9A9A9;
}

@media screen and (min-width:500px) {
    .shop_logos {
        display: grid;
        grid-template-columns: repeat(auto-fit, 4.5rem);
        gap: 0.611rem;
        grid-gap: 0.611rem;
    }
}