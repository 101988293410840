.error_msg {
    color: rgba(255, 0, 0, 0.811);
    font-size: 0.85rem;
    margin-bottom: 14px;
    margin-top: -12px;
}

.input.error {
    border: 1px solid rgba(255, 0, 0, 0.245);
    border-radius: 7px;
}

button {
    cursor: pointer;
}