.category_card_root {
    border-radius: 10px;
    color: white;
    width: 120px;
}

.category_card_root img {
    max-width: 80px;
    height: 30px;
}

.category_card_root .outer {
    border-radius: 10px;
    border-bottom-right-radius: 33px;
}

.category_card_root .second_outer {
    padding: 15px 15px;
    border-radius: 10px;
    border-bottom-right-radius: 60px;
    padding-right: 0;
}

.category_title {
    font-size: 0.7rem;
}

.category_card_root.red .second_outer {
    background-color: rgb(255, 0, 0);
}

.category_card_root.red .outer {
    background-color: rgb(255, 79, 79);
}

.category_card_root.red {
    background-color: rgb(255, 116, 116);
}



.category_card_root.purple .second_outer {
    background-color: #823AFD;
}

.category_card_root.purple .outer {
    background-color: #9A5FFD;
}

.category_card_root.purple {
    background-color: #AC7DFD;
}


.category_card_root.yellow .second_outer {
    background-color: #EEB229;
}

.category_card_root.yellow .outer {
    background-color: #F0BD46;
}

.category_card_root.yellow {
    background-color: #F3CA6A;
}

.category_card_root.green .second_outer {
    background-color: #04B058;
}

.category_card_root.green .outer {
    background-color: #1bb767;
}

.category_card_root.green {
    background-color: #3BC17C;
}

.category_card_root.pink .second_outer {
    background-color: #A54E9D;
}

.category_card_root.pink .outer {
    background-color: #B56DAE;
}

.category_card_root.pink {
    background-color: #C288BD;
}

.category_card_root.blue .second_outer {
    background-color: #07AAE0;
}

.category_card_root.blue .outer {
    background-color: #32B9E5;
}

.category_card_root.blue {
    background-color: #5DC8EB;
}