.root-modal-overlay {
  position: fixed;
  background-color: rgba(74, 74, 74, 0.831);
  z-index: 44;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.root-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgb(255, 255, 255);
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: max-content;
  z-index: 48;
  border-radius: 1.875rem;
  padding: 0 1.875rem;
  width: 367px;
  max-width: 95vw;
  min-height: 300px;
  max-height: 90vh;
  overflow: auto;
}

.root-modal-container .modal-header {
  padding-top: 23px;
}

.root-modal-container .modal-body {}

.modal-header .fa-times {
  margin-left: 25px;
  color: rgba(31, 31, 31, 0.602);
}

.modal-header .fa-times:hover {
  color: rgba(31, 31, 31, 0.829);
  transform: scale(1.1);
  cursor: pointer;
}

.modal-header .modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.custom-modal-footer {}

.modal-root-cont {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

.modal-body {
  /* width: 700px; */

  max-width: 90vw;
}