.login-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;
    background-color: white;
}

.login-page form {
    width: 100%;
    padding: 10px;
}

.login-page .icon_cont {
    margin-top: -2px;
}

.center-in-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 8;
    width: 100%;
}

.login_bottom {
    flex: 2;
}

.input {
    position: relative;
    margin-bottom: 15px;
    width: 100%;

}

.input input {
    border: none;
    outline: none;
    background: #F7F7F7;
    border-radius: 6px;
    padding: 20px 44px;
    width: 100%;
}

.input input:focus {
    outline: 1px solid #4277DD;

}


.input input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #ADB0BA;

}

.input .icon_cont {
    position: absolute;
    height: 100%;
    margin-left: 20px;
    display: flex;
}

.login-btn {
    background: linear-gradient(90deg, #1488CC 0%, #2B32B2 100%);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #FBFBFB;
    padding: 17px 0;
    text-align: center;
    width: 100%;
    border: none;
}

.login_form_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #000000;
}

.forgot_password {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #6F7382;
    margin-bottom: 20px;
}

.info {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #383B52;
    margin-top: 42px;
    margin-bottom: 23.5px;
    text-align: center;
}

a {
    text-decoration: none;
}