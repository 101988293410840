.user_page_cont .main_icon {
    padding: .6rem;
    border-radius: .625rem;
    margin-right: .875rem;
}

.user_page_cont .text .title_text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #333333;
    padding-bottom: .175rem;
}

.user_page_cont .text .link_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #2565FF;
    cursor: pointer;
}

.user_page_cont .created_at {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.125rem;
    margin-right: .5rem;
    color: #A9A9A9;
}


.user_page_cont .item {
    padding: 1rem 0.2rem;
}

.nm-1rem {
    margin-top: -.875rem;
}

.user_page_cont .item:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.transactions {
    overflow-y: scroll;
    max-height: 50vh;
}

.transactions::-webkit-scrollbar {
    display: none;
}

.transaction {
    padding: .75rem 0;
}

.transaction:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.transaction .transaction_order {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #A9A9A9;
    margin-right: 1.25rem;
}

.transaction .transaction_bank {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #333333;
}

.transaction .tra_card_no {
    font-weight: 500;
    font-size: .75rem;
    line-height: .9375rem;
    color: #A9A9A9;
    margin-top: .25rem;
}

.mr-8 {
    margin-right: .5rem;
}