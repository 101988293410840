.root_nav_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: var(--color-primary);
    color: white;

}

.sticky_top_nav {
    position: sticky;
    top: -1px;
    z-index: 99;
    transition: all 0.2s;
}

.header_text {
    font-size: 0.9rem;
}

.badge_dot::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: black;
    display: block;
    position: absolute;
    right: 4px;
    top: 3px;
    border-radius: 30px;
    z-index: 2;
}

.badge_green::after {
    background-color: rgb(113, 221, 113) !important;
}