.my_cards_categories {
    display: flex;
    gap: 5px;
    grid-gap: 5px;
}

.my_cards_page .category_card {
    border-radius: 0 !important;
}

.my_cards_page .cards_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1.25rem;
    gap: 1.25rem;

}

.my_cards_page .cards_list a {
    text-decoration: none;
}

.my_cards_page .cards_list .bankcard_root {
    max-width: 480px;
    width: 100%;
}

.my_cards_categories li {
    list-style: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #A9A9A9;
    padding: 0.5rem 1.188rem;
    white-space: nowrap;
}

.my_cards_categories .pill {
    background: rgba(37, 101, 255, 0.16);
    border-radius: 63px;
    font-weight: 500;
    color: #2565FF;
}