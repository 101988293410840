.shop_page_cont .icon_cont {
    width: 3.75rem;
    height: 3.75rem;
    margin-right: 0.875rem;
    border-radius: .625rem;
}

.shop_page_cont .icon_cont img {
    width: 100%;
    height: 100%;
}

.fs_20 {
    font-size: 1.25rem;
}

.fs_14 {
    font-size: .875rem;
}

.root-modal-container .icon_cont {
    width: 5rem;
    height: 5rem;
    margin-right: 0.875rem;
    border-radius: .625rem;
}

.root-modal-container .icon_cont img {
    width: 100%;
    height: 100%;
}

.shop_page_cont .order {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-right: 0.75rem;
    color: #A9A9A9;
}

.shop_page_cont .tag {
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 0.875rem;
    border-radius: 1.688rem;
    padding: 3px 8px;
    margin-top: 8px;
    text-align: center;
}

.shop_item_modal_desc {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #A9A9A9;
    margin-top: 1.25rem;
}