.restaurant_page .shop_item img {
    border-radius: 10px;
}

.restaurant_page_root {
    background: #E5E5E5;
}

.restaurant_page_root .category_card {
    border-radius: 0;
}

.page {
    min-height: 100vh;
}