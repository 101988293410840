.message_page .search_bar_root input {
    width: 100% !important;
    padding-bottom: 0.45rem !important;
}

.message_page .search_bar_root {
    margin: 0;
}


.message_page .input_search {
    width: 100% !important;
    border: 1px solid #DCDCDC;
    border-radius: 64px;
    margin-right: 0.625rem;

}

.search_bar_root .active_pill {
    display: flex;
    align-items: center;
    font-weight: 500;
    padding-left: 1rem;
    padding-right: 0.62rem;
    height: 100%;
}

.search_bar_root .active_pill svg {
    margin-left: 0.32rem;
}

.drop_menu_cont li {
    list-style: none;
}

.relative {
    position: relative;
}

.drop_menu_cont .menu_list {
    list-style: none;
    border: 1px solid #C6D6FF;
    border-radius: 10px;
    padding: 0.75rem;
    background-color: white;
    min-width: 5.875rem;
}

.drop_menu_cont {
    padding-top: 0.6rem;
}

.drop_menu_cont .menu_item {
    font-weight: 450;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #999999;
    cursor: pointer;
}

.drop_menu_cont .menu_item.active {
    color: blue;
    font-weight: 500;
}

.drop_menu_cont .menu_item:not(:last-child) {
    margin-bottom: 1rem;

}

.drop_menu_cont {
    display: none;
}

.drop_menu_label:hover~.drop_menu_cont {
    display: unset;
}

.drop_menu_cont:hover {
    display: unset;

}


.chat_box .profile_img {
    margin-right: 0.825rem;
}

.chat_box .profile_img img {
    width: 100%;
    height: 100%;
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
    object-fit: cover;
}

.chat_box .message {
    background: #F7F7F7;
    border-radius: 0px 20px 20px 20px;
    padding: 0.813rem 1.25rem;
}

.chat_box .message_info {
    font-weight: 450;
    font-size: .625rem;
    line-height: .8125rem;
    color: #A5A5A5;
    padding-left: 0.813rem;
    margin-top: .25rem;
}

.chat_box .menu_icon {
    padding: .625rem;
    color: #797979;
}

.other_user {
    margin-top: .625rem;
    justify-content: flex-end;
}

.other_user .profile_img {
    margin-left: 0.825rem;
    margin-right: 0;
}

.other_user .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.other_user .message_info {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.813rem;
    padding-left: 0;

}

.other_user .message {
    background: #E2EEFF;
    border-radius: 20px 20px 0px 20px;
    padding: 0.813rem 1.25rem;
    margin-left: 2.875rem;
    width: max-content;
    margin-bottom: 0.25rem;
}

.other_user .message.last {
    background: #E2EEFF;
    border-radius: 20px 0px 20px 20px;
    padding: 0.813rem 1.25rem;
}

.session_chat {
    padding: 0.75rem 0;
}

.session_chat:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}