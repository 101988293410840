.bankcard_root {
    color: white;
    /* padding: 15px 20px; */
    /* min-height: 180px; */
    border-radius: 10px;
    min-width: 280px;
    width: 350px;
    max-width: 80vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.bankcard_root.purple {
    background: linear-gradient(to right, #5B377D, #2D0358);

}

.bankcard_root.blue {
    background: linear-gradient(to right, #2C4264, #071D3D);

}

.bankcard_root.black {
    background: linear-gradient(to right, #454545, #090808);

}

.card_logo {
    width: 60px;
    height: 30px;
    background-color: #ffa600;
    z-index: 2;
}

.bankcard_root .card_type {
    text-transform: uppercase;
    letter-spacing: 7px;
    font-size: 0.75rem;
    z-index: 2;

}

.bankcard_root .card_number {
    text-transform: uppercase;
    letter-spacing: 5px;
    z-index: 2;
    position: relative;

}

.bankcard_root .card_number span {
    margin-right: 12px;
    z-index: 2;
}

.bankcard_root .card_label {
    font-size: 0.75rem;
    font-weight: 300;
    z-index: 2;


}

.card_holder {
    margin-right: 65px;
}

.bankcard_root .card_label_value {
    font-size: 0.9rem;
    z-index: 2;

}

.two_circles {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
}

.two_circles div {
    width: 2em;
    height: 2em;
    border-radius: 100px;
}

.two_circles .circle_red {
    background-color: red;
    position: relative;
    right: -8px;
    z-index: 1;

}

.two_circles .circle_yellow {
    background-color: rgb(255, 205, 4);
    z-index: 2;
    opacity: 0.8;
}

.absolute {
    position: absolute;
}

.bg_circle_1 {
    width: 60px;
    height: 60px;
    border-radius: 500px;
    border: 1px solid #274b81;
    z-index: 1;
    left: -17px;
    top: 60px;
}

.bg_circle_2 {
    width: 20px;
    height: 20px;
    border-radius: 500px;
    border: 1px solid #274b81;
    z-index: 1;
    left: 100px;
    top: 50px;
}

.bg_circle_3 {
    width: 100px;
    height: 100px;
    border-radius: 500px;
    border: 1px solid #274b81;
    z-index: 1;
    left: 70px;
    bottom: -70px;
}

.bg_circle_4 {
    width: 40px;
    height: 40px;
    border-radius: 500px;
    border: 1px solid #274b81;
    z-index: 1;
    left: 140px;
    top: -25px;
}

.bg_circle_5 {
    width: 130px;
    height: 130px;
    border-radius: 500px;
    border: 1px solid #1a365f;
    z-index: 1;
    right: -60px;
    top: -35px;
}

.bg_circle_5 {
    width: 40px;
    height: 40px;
    border-radius: 500px;
    border: 1px solid #1a365f;
    z-index: 1;
    right: 90px;
    top: 80px;
}

.purple .bg_circle {
    border-color: #4b395d;
    z-index: 1 !important;
}

.black .bg_circle {
    border-color: #343236;
    z-index: 1 !important;
}