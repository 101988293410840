.card_detail_card_cont {
    padding-bottom: 0.875rem;
}

.no_radius {
    border-radius: 0 !important;
}

.active_pill {
    border-radius: 63px;
    padding: 0.5rem 1.4rem;
}

.active_pill.sky_blue {
    background: rgba(1, 179, 255, 0.16);
}

.active_pill.blue {
    background: rgba(37, 101, 255, 0.16);
}

.active_pill.yellow {
    background: rgba(252, 132, 21, 0.16);
}

.active_pill.purple {
    background: rgba(153, 93, 255, 0.16);
}

.active_pill.green {
    background: rgba(2, 175, 86, 0.16);
}

.dial_to_sticky {
    position: fixed;
    bottom: 2.1rem;
    right: 2rem;
    z-index: 3;
    background: #337BE8;
    border-radius: 3rem;
    padding: 1rem
}

.card_details_page .card {
    padding: 12px;
}

.min_height_detail_card {
    min-height: calc(100vh - 370px);
}