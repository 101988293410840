 .file_page_cont .create_new {
     font-weight: 500;
     font-size: 1rem;
     line-height: 1.25rem;
     color: var(--color-primary);
     border: none;
     background: rgba(37, 101, 255, 0.16);
     border-radius: 1.875rem;
     padding: .625rem 1rem;
     display: flex;
     align-items: center
 }

 .file_page_cont .create_new svg {
     margin-left: 0.4rem;
 }

 .file_note_card {
     box-shadow: 0px .25rem .75rem rgba(0, 0, 0, 0.1);
     border-radius: .625rem;
 }

 .file_note_card .card_detail {
     padding: 1.25rem;

 }

 .file_note_card .header {
     padding: 1.25rem;

 }


 .file_note_card.open {
     background: #EBF1FF;
 }

 .file_note_card .note_text {
     font-weight: 450;
     font-size: .875rem;
     line-height: 1.125rem;
     color: #333333;
     padding: 1.25rem 0;
 }

 .file_note_card .seperator {
     border-top: 1px solid rgba(37, 101, 255, 0.16);
 }

 .file_note_card .action_buttons button:not(:last-child) {
     margin-right: 1rem;
 }

 .file_note_card .created_at {
     gap: 1rem;
     grid-gap: 1rem;
     font-weight: 500;
     font-size: .875rem;
     line-height: 1.125rem;
     color: #A9A9A9;
 }

 .file_note_card:not(:last-child) {
     margin-bottom: 0.875rem;
 }

 .file_note_card:last-child {
     margin-bottom: 3rem;
 }

 .create_note_modal_cont {
     background: #E9EEF7 !important;
 }

 .create_note_modal_cont .create_note_input {
     padding: .75rem 1rem;
     background: #FFFFFF;
     border-radius: .625rem;
     border: none;
     margin-top: 1rem;
     outline: none;

 }

 .create_note_modal_cont textarea {
     min-height: 6.75rem;

 }

 .create_note_modal_cont .create_note_input::placeholder {
     font-weight: 450;
     font-size: .875rem;
     line-height: 1.125rem;
     color: #333333;
 }

 .create_note_modal_cont button {
     border-radius: 3.0625rem;
     padding: .875rem 0;
     border: none;
     outline: none;
     font-family: 'Rubik';
     font-style: normal;
     font-weight: 500;
     font-size: 1rem;
     margin-top: 1rem;
     flex: 1;
 }

 .create_note_modal_cont .btn-primary {
     background: #6391FF;
     color: white;
 }

 .create_note_modal_cont .btn-secondary {
     background: rgba(99, 145, 255, 0.16);
     color: var(--color-primary);
 }