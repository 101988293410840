.notification_item {
    padding-bottom: .875rem;
    border-bottom: 2px solid rgba(169, 169, 169, 0.3);
}

.notification_item .image_cont {
    width: 41px;
    height: 41px;
    border-radius: 10px;
}

.notification_item .text_light {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #A9A9A9;
    margin-bottom: 1px;
}

.notification_item .description {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
}

.notification_item .time_ago {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.notifications_cont {}

.clear_all_btn {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #2565FF;
    margin-top: 14px;
}