.section_heading {
    font-weight: 500;
    font-size: 1.13rem;
}

.cards_scroll {
    display: flex;
    width: max-content;

}

.cards_scroll a {
    margin-right: 1.25rem;
    text-decoration: none;

}

.category_card_root {
    margin-right: .9375rem;
}

.cards_scroll::-webkit-scrollbar {
    display: none;
}

.my_card_cont {
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.104);
    padding-bottom: 25px;
}

.my_cards_cont .my_card_cont {
    min-height: 150px;
}

.gap-15 {
    gap: 15px;
    grid-gap: 15px;

}

.view_all_btn {
    font-size: 0.9rem;
    color: rgb(121, 121, 121);
}

.card {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 1.125rem 1.375rem;
}









/* Discover Card css */

.discover_card {
    height: 161.12px;
    max-width: min(calc(100vw - 2.5rem), 430px);
    border-radius: 17.9024px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.discover_card_2 {
    background: #1D74FF;

}

.discover_card_1 {
    background: #0149F6;

}

.discover_card img {
    border-radius: 17.9024px;
    width: 100%;
}

.bankcard_root .credit_image {
    max-height: 172px;
    height: auto;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;

}

.bankcard_root.img {
    width: auto;
}

.py-0 {
    padding-right: 0;
    padding-left: 0;
}