.info_icon_cont {
    border-radius: 0.625rem;
    padding: 0.5rem;
    margin-right: 0.875rem;
}

.info_icon_cont svg {
    width: 2.75rem;
}

.info_page_item .text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #333333;
}

.info_page_item .text_secondary {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #B9B9B9;
    margin-top: 0.375rem;
}

.info_page_root {
    margin-top: -0.75rem;
}

.info_page_item {
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.border_none {
    border: none;
}