.side_nav_root {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: white;
    width: 85vw;
    height: 100vh;


}

.side_nav_padding {
    padding: 1.375rem;
}

.back_drop {
    background-color: rgba(0, 0, 0, 0.427);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;

}

.h-100 {
    height: 100%;
}



.btn.logout {
    margin-top: auto;
    background: rgba(235, 53, 63, 0.16);
    border-radius: 10px;
    padding: 15.4px 19.4px;
    margin: 30px;
    border: none;
    outline: none;
    color: red;
}



.side_nav_root .user_image {
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 6.25rem;
    margin-right: 1.25rem;
}

.side_nav_root .user_image img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 100px;

}

.side_nav_root .user_name {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: #333333;
}

.side_nav_root .description_text {
    font-weight: 450;
    font-size: .875rem;
    line-height: 1.125rem;
    color: rgba(51, 51, 51, 0.7);
}

.side_nav_root .icon_cont {
    width: 1.4212rem;
    margin-right: 1.5625rem;
}

.side_nav_root .menu_item {
    padding: 0.75rem 0;
}

.side_nav_root .menu_items {
    padding-top: 1.75rem;
    border-top: 1px solid #D3D3D3;
}

.side_nav_root .user_info {
    padding-bottom: 1.5rem;
}

.side_nav_root .close_cont {
    margin-top: 1.5rem;
    margin-right: 1.5rem;
}

.side_nav_root .logout_icon {
    margin-right: .9875rem;
}