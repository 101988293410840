.go_back_header {
    background: var(--color-primary);
    color: white;
    padding: 1.063rem 1.375rem;
}

.go_back_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}