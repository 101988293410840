.search_bar_root {
    margin: 0 20px;
    padding-bottom: 13px;
}

.search_bar_root .input_search {
    display: flex;
    width: 100%;
    border-radius: 50px;
    background-color: white;
    border-radius: 50px;
    padding: 3px 15px;
    align-items: center;

}

.search_bar_root input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px 10px;
}

.search_bar_root input::placeholder,
.search_bar_root .icon_cont {
    color: rgb(154, 154, 154);
}